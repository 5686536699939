
import axios, { AxiosResponse, RawAxiosRequestHeaders } from "axios";
import { clearUser, getUser, setUser } from "./credentialsStore";
import { Archivo, ContractIdParams, EditarArchivo, SubirArchivo, TempFileUpload, User } from "src/util/types";

export const ERROR_MESSAGES: { [code: number]: string } = {
	0x0001: 'Usuario o contraseña inválido, intente nuevamente',
	0x0002: 'Contraseña incorrecta',
};

const client = axios.create({ baseURL: "https://88pdfkcqm3.execute-api.us-east-1.amazonaws.com/prod/" });

function processResponse(response: AxiosResponse)
{
	if (!response.data.ok)
	{
		// Handle invalid token
		if (response.data.errorCode == 0x03)
		{
			clearUser();
			return;
		}

		const message = ERROR_MESSAGES[response.data.errorCode] ??
			response.data.error;
		throw new Error(message);
	}
	return response.data.data;
}

function generateHeaders(): RawAxiosRequestHeaders
{
	const user = getUser();
	return user?.token ?
		{ token: user.token } : {};
}

async function get<T>(url: string, params?: any): Promise<T>
{
	const headers = generateHeaders();
	const response = await client.get(url, { params, headers });
	return processResponse(response) as T;
}

async function post<T>(url: string, data: any): Promise<T>
{
	const headers = generateHeaders();
	const response = await client.post(url, data, { headers });
	return processResponse(response) as T;
}

export const login = async (email: string, password: string) => {
	const user = await post<User>('login', { email, password });
	setUser(user);
	return user;
};
export const logout = () => {
	clearUser();
};

export const uploadFile = async (url: string, file: any) =>
{
	await client.put(
		url,
		file,
		{
			headers:
			{
				"Content-Type": file.type
			}
		});

}
export const requestTempFileUpload = async () => get<TempFileUpload>('requestTempFileUpload');
export const addFile = (tempFile: string, file: SubirArchivo) => post('addFile', { tempFile, file });
export const updateFile = async (req: EditarArchivo) => post('updateFile', req);
export const deleteFile = async (id: string) => post('deleteFile', { id });
export const getFiles = (params: ContractIdParams) => get<Archivo[]>('getFiles', params);
export const getFile = (id: string) => get<Archivo[]>('getFile', { id });
export const downloadFile = (id: string) => get<string>('downloadFile', { id });
