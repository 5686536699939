
export interface TempFileUpload {
  url: string;
  file: string;
}

export interface User {
  id:           string;
  email:        string;
  name:         string;
  contracts:    Contract[];
  tokenVersion: number;
  token:        string;
}

export interface Contract {
  id:   string;
  name: string;
}

export interface SubirArchivo {
  nombre: string;
  nombre_documento: string;
  descripcion: string;
  tipo: string; // Ejemplo: decreto
  fecha_emision: string | null;
  mime_type: string;
  contractid: string;
}

export interface Archivo extends SubirArchivo {
  id: string;
  fecha_incorporacion: string;
}

export interface EditarArchivo extends Omit<SubirArchivo, 'mime_type' | 'nombre_documento' | 'fecha_incorporacion'>{
  id: string;
}

export enum FileActionState {
  NOT_STARTED,
  SUCCESS,
  ERROR,
  LOADING
}

export interface RequestTempFileUploadResponse {
  fileName: string;
  url: string;
}

export interface ContractIdParams {
  contractid: string;
}
