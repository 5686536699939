import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Contract, User } from "src/util/types";

interface LoginStateType {
    error?: string | object | null | undefined | unknown,
    user: User,
    history?: any
    contracts: Contract[],
    contractSelected: Contract;
}
export const initialState: LoginStateType = {
    error: '',
    user: {
        id: "",
        email: "",
        name: "",
        contracts: [],
        tokenVersion: 0,
        token: ""
    },
    contracts: [],
    history: '',
    contractSelected: {
        id: '',
        name: ''
    }
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        apiError(state, action) {
            state.error = action.payload.message
        },
        loginSuccess(state, action: PayloadAction<User>) {
            state.user = action.payload
            state.contracts = action.payload.contracts
            state.contractSelected = action.payload.contracts[0];
        },
        logoutUserSuccess(state, action) {
            state.error = '';
            state.user = initialState.user;
            state.history = action.payload
        },
        contractChange(state, action: PayloadAction<Contract>) {
            state.contractSelected = action.payload
        }
    },
});

export const {
    apiError,
    loginSuccess,
    logoutUserSuccess,
    contractChange
} = loginSlice.actions

export default loginSlice.reducer;