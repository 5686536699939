import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

//redux
import { useSelector } from "react-redux";

// withRouter
import withRouter from "src/components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import userIcon from "../../assets/images/users/user-icon.png";
// actions
import { RootState } from "src";


const UserProfile = () => {
  document.title = "Perfil de usuario | Libro de Contratos Digital";

  const { user } = useSelector((state: RootState)=> state.login);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Libro de Contratos Digital" breadcrumbItem="Perfil" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={userIcon}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center ms-3">
                      <div className="text-muted">
                        <h5>{user.name}</h5>
                        <p className="mb-1">{user.email}</p>
                        <p className="mb-0">Id no: #{user.id}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);